<template>
  <cw-page
    icon="check"
    class="continuous-loan__complete"
  >
    <template #hero-title>
      <translate>
        Thank you for choosing our service!
      </translate>
    </template>

    <template #hero-text>
      <translate>
        Welcome new customer!
      </translate>
    </template>

    <template>
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <p class="mb-6">
            <translate>
              Thank you for choosing our service and welcome to Saldo!
            </translate>
          </p>
          <p
            id="continuous-loan__complete__loan-limit"
            v-translate="{
              limit: $options.filters.currency(offer.loanLimit, { locale }),
              openingTag: '<b>',
              closingTag: '</b>',
            }"
            render-html="true"
          >
            Your credit limit %{ openingTag }%{ limit }%{ closingTag } is available 24 hours
            a day, 7 days a week.
          </p>
          <p
            v-if="isNightWithdrawal"
            id="continuous-loan__complete__withdrawal-amount"
            v-translate="{
              amount: $options.filters.currency(initialData.withdrawalAmount, { locale }),
              formattedNightWithdrawalTime,
              openingTag: '<b>',
              closingTag: '</b>',
            }"
            render-html="true"
          >
            First %{ openingTag }%{ amount }%{ closingTag } Withdrawal will be paid to the
            account you specified %{ openingTag } at the earliest
            %{ formattedNightWithdrawalTime }%{ closingTag } o'clock.
          </p>
          <p
            v-else
            id="continuous-loan__complete__withdrawal-amount"
            v-translate="{
              amount: $options.filters.currency(initialData.withdrawalAmount, { locale }),
              openingTag: '<b>',
              closingTag: '</b>',
            }"
            render-html="true"
          >
            The first withdrawal of %{ openingTag }%{ amount }%{ closingTag } will be paid
            to the account specified in the loan application.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <cw-review :data="initialData"/>
        </v-col>
      </v-row>

      <v-row
        justify="center"

        class="continuous-loan__complete__cards fill-height"
      >
        <v-col
          cols="12"
          class="continuous-loan__complete__card"
        >
          <v-card class="fill-height">
            <v-img
              :src="require('@shared/assets/images/sms.jpg')"
              class="white--text"
              height="200px"
            >
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    color="primary"
                    indeterminate
                  />
                </v-row>
              </template>
            </v-img>
            <v-card-title>
              <translate>
                You can also withdraw money via SMS
              </translate>
            </v-card-title>
            <v-card-text class="text-xs-left">
              <span
                v-translate="{
                  openingTag: '<b>',
                  closingTag: '</b>',
                  openingTag2: '<i>',
                  closingTag2: '</i>',
                }"
                render-html="true"
              >
                You can make withdrawals by sending a SMS %{ openingTag }NOSTO%{ closingTag }
                %{ openingTag2 }sum%{ closingTag2 }, for example
                %{ openingTag }NOSTO 500%{ closingTag } to the number
                %{ openingTag }16520%{ closingTag }.
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwCompleteContinuousLoan',

  components: {
    'cw-review': () => import('@shared/components/Review'),
  },

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
      offer: 'application/getSelectedOffer',
    }),

    isNightWithdrawal() {
      const { nightWithdrawalTime } = this.initialData;

      if (nightWithdrawalTime) return true;

      return false;
    },

    formattedNightWithdrawalTime() {
      const { nightWithdrawalTime } = this.initialData;

      if (!nightWithdrawalTime) return '';

      return this.$filters.date(nightWithdrawalTime, {
        type: 'time',
        locale: this.locale,
      });
    },
  },
};
</script>
